import React from 'react';
import styled from 'styled-components';
// import { graphql, useStaticQuery } from 'gatsby';

import { Header } from './Header';
import { Footer } from './Footer';
import { colors } from '@utilities/theme';
import '../layout.css';
import { Menu } from './Nav/MobileNav/Menu';
import { Pricing } from './Pricing';

export type Pages =
  | 'screen-printing'
  | 'embroidery'
  | 'vinyl'
  | 'gallery'
  | 'about'
  | 'contact'
  | 'catalogs'
  | '404';

interface Props {
  page: Pages;
}

export const Layout: React.FC<Props> = ({ children, page }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <Container>
      <Menu />
      <Header page={page} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1440,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <Main>{children}</Main>
        {/* <footer>
          {`© ${new Date().getFullYear()}, Built with`}
          {` `}
          <a href="https://www.gatsbyjs.org">{`Gatsby`}</a>
        </footer> */}
      </div>
      <Pricing page={page} />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  background: white;
  color: ${colors.black};
`;

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr;
`;
