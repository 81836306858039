import React from 'react';
import styled from 'styled-components';

import { Icon } from '@svg/icon';
import { colors, fonts } from '@utilities/theme';
import { Heading } from '@components/Headings';
import { Pages } from './index';

interface Props {
  page: Pages;
}

export const Pricing = ({ page }: Props) => {
  return page === 'screen-printing' ||
    page === 'embroidery' ||
    page === 'vinyl' ? (
    <PricingSection page={page}>
      <Title
        light
        behindText={
          page === 'screen-printing'
            ? 'ballpark'
            : page === 'embroidery'
            ? 'embroidery'
            : 'vinyl/digital'
        }
      >
        pricing
      </Title>
      {page === 'screen-printing' && (
        <>
          <TShirt>
            <Icon name="tshirt" />
            <Item>t-shirt</Item>
            <Price>$9</Price>
          </TShirt>
          <LongSleeve>
            <Icon name="long-sleeve" />
            <Item>long sleeve</Item>
            <Price>$12</Price>
          </LongSleeve>
          <Sweatshirt>
            <Icon name="sweatshirt" />
            <Item>sweatshirt</Item>
            <Price>$24</Price>
          </Sweatshirt>
          <Hoody>
            <Icon name="hoody" />
            <Item>hoody</Item>
            <Price>$26</Price>
          </Hoody>
        </>
      )}
      {page === 'embroidery' && (
        <>
          <Names page={page}>
            <Icon name="nametag" />
            <Item>names</Item>
            <Price>$5</Price>
          </Names>
          <Digitizing>
            <Icon name="computer" />
            <Item>digitizing</Item>
            <Price>$30</Price>
          </Digitizing>
          <Logos>
            <Icon name="create" />
            <Item>custom logos</Item>
            <Price>$9</Price>
          </Logos>
        </>
      )}
      {page === 'vinyl' && (
        <>
          <Names page={page}>
            <Icon name="nametag" />
            <Item>names</Item>
            <Price>$5</Price>
          </Names>
          <Details>
            Vinyl and Digital transfers are highly specific and we would need to
            see artwork before a quote can be given (contact us)
          </Details>
        </>
      )}
      <GeneralPricing page={page}>
        {page === 'screen-printing' && (
          <>
            <p>* $24 screen setup</p>
            <p>* $10 screen re-setup</p>
          </>
        )}
        <Disclaimer>Disclaimer: These are ballpark prices.</Disclaimer>
      </GeneralPricing>
    </PricingSection>
  ) : (
    <React.Fragment />
  );
};

interface PricingSectionProps {
  page: Pages;
}

const PricingSection = styled.section<PricingSectionProps>`
  margin-bottom: -55px;
  background: ${colors.orange};
  color: white;
  display: grid;
  grid-template-columns: ${({ page }) =>
    page === 'screen-printing'
      ? 'repeat(4, auto)'
      : page === 'embroidery'
      ? 'repeat(3, 1fr)'
      : 'repeat(2, 1fr)'};
  grid-template-rows: repeat(3, auto);
  grid-template-areas: ${({ page }) =>
    page === 'screen-printing'
      ? `
    'title title title title'
    'tshirt longsleeve sweatshirt hoody'
    'general general general general'
  `
      : page === 'embroidery'
      ? `
    'title title title'
    'names digitizing logos'
    'general general general'
  `
      : `
    'title title'
    'names details'
    'general general'
  `};
  padding: 75px 30px 50px;

  @media (max-width: 700px) {
    grid-template-columns: ${({ page }) =>
      page === 'vinyl' ? '1fr 1fr' : 'auto auto'};
    grid-template-rows: repeat(4, auto);
    grid-template-areas: ${({ page }) =>
      page === 'screen-printing'
        ? `
      'title title'
      'tshirt longsleeve'
      'sweatshirt hoody'
      'general general'
    `
        : page === 'embroidery'
        ? `
      'title title'
      'names digitizing'
      'logos logos'
      'general general'
    `
        : `
      'title title'
      'names details'
      'general general'
    `};
  }
`;

const Title = styled(Heading)`
  grid-area: title;
`;

const PricedItemGrid = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: start;
  row-gap: 1em;
`;

const TShirt = styled(PricedItemGrid)`
  grid-area: tshirt;
`;

const LongSleeve = styled(PricedItemGrid)`
  grid-area: longsleeve;
`;

const Sweatshirt = styled(PricedItemGrid)`
  grid-area: sweatshirt;

  @media (max-width: 700px) {
    margin-top: 3em;
  }
`;

const Hoody = styled(PricedItemGrid)`
  grid-area: hoody;

  @media (max-width: 700px) {
    margin-top: 3em;
  }
`;

const Names = styled(PricedItemGrid)<PricingSectionProps>`
  grid-area: names;
  align-self: ${({ page }) => page === 'vinyl' && 'center'};
`;

const Digitizing = styled(PricedItemGrid)`
  grid-area: digitizing;
`;

const Logos = styled(PricedItemGrid)`
  grid-area: logos;

  @media (max-width: 700px) {
    margin-top: 4em;
  }
`;

const Details = styled.p`
  grid-area: details;
  max-width: 500px;
  font-family: ${fonts.secondary};
  font-weight: 500;
  font-size: 24px;
  color: white;
  align-self: center;
  line-height: 1.5;
`;

const Item = styled.h3`
  font-family: ${fonts.primary};
  font-weight: 500;
  font-size: 36px;
  color: white;
  text-transform: uppercase;
  margin: 0;
`;

const Price = styled(Item)`
  font-size: 45px;
  color: ${colors.secondary};
  margin: 0;
`;

const GeneralPricing = styled.div<PricingSectionProps>`
  margin-top: 1em;
  grid-area: general;
  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  font-family: ${fonts.primary};
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 500;

  @media (max-width: 700px) {
    margin-top: ${({ page }) => (page === 'screen-printing' ? '3em' : '1em')};
  }
`;

const Disclaimer = styled.p`
  font-family: ${fonts.secondary};
  font-style: italic;
  color: white;
  font-size: 12px;
  text-transform: none;
`;
