import React from 'react';
import { Gallery as GalleryComponent } from 'gatsby-theme-gallery';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Layout } from '../components/Layout';
import { Heading } from '../components/Headings';
// import { colors } from '../components/utilities/theme';

const Gallery = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Layout page="gallery">
        <Title behindText="hand made">finished pieces</Title>
        <GalleryContainer>
          <GalleryComponent />
        </GalleryContainer>
      </Layout>
    </motion.div>
  );
};

export default Gallery;

const GalleryContainer = styled.section`
  position: relative;
  z-index: 10;
  padding: 25px;
  border-radius: 2px;
  background: white;
  box-shadow: 0px -6px 16px rgba(0, 0, 0, 0.025),
    0px 13px 27px rgba(50, 50, 93, 0.25);
`;

const Title = styled(Heading)`
  margin-top: 15vh;

  @media (max-width: 900px) and (orientation: landscape) {
    margin-top: 45vh;
  }
`;
